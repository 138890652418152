var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.consolidatedLevel && _vm.transitionLevel
    ? _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "pb-0", attrs: { cols: "12" } }, [
                    _c(
                      "span",
                      { staticClass: "primary_font--text card-title" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.cards.LevelCard.levels_system." +
                                  _vm.levelsSystem.current.toLowerCase() +
                                  ".consolidated_text"
                              ) + ":"
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "ml-1 card-title-name",
                        style: _vm.consolidatedLevelNameStyle
                      },
                      [_vm._v(" " + _vm._s(_vm.consolidatedLevel.name) + " ")]
                    )
                  ]),
                  _c("v-col", { staticClass: "pt-0", attrs: { cols: "12" } }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.cards.LevelCard.accumulated_amount"
                            ) + ":"
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "caption primary_font--text font-weight-bold"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dynamicPoint")(
                                _vm.consolidatedAccumulatedAmount
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c("LevelProgressBar", {
                attrs: {
                  level: _vm.consolidatedLevel,
                  accumulatedAmount: _vm.consolidatedAccumulatedAmount
                }
              }),
              _vm.shouldShowTransitionLevelProgressBar
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "primary_font--text font-weight-bold card-title"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.cards.LevelCard.levels_system.renovation.transition_text"
                                        ) + ":"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-1 card-title-name",
                                  style: _vm.transitionLevelNameStyle
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.transitionLevel.name) + " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.cards.LevelCard.accumulated_amount"
                                      ) + ":"
                                    ) +
                                    " "
                                )
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "caption primary_font--text font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("dynamicPoint")(
                                          _vm.transitionAccumulatedAmount
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("LevelProgressBar", {
                        attrs: {
                          level: _vm.transitionLevel,
                          accumulatedAmount: _vm.transitionAccumulatedAmount
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider", { staticClass: "my-4" }),
              _c(
                "v-row",
                { staticClass: "px-4" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-sm-0",
                      attrs: { cols: "12", xs: "12", sm: "6" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pb-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-1", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "primary_font--text font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.cards.LevelCard.levels_system_header"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0 ", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                { staticClass: "primary_font--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.cards.LevelCard.status"
                                        ) + ":"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              !_vm.levelsSystem.next
                                ? _c(
                                    "span",
                                    { staticClass: "caption success--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.cards.LevelCard.status_updated"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "caption error--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.cards.LevelCard.status_pending"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                { staticClass: "primary_font--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.cards.LevelCard.current"
                                        ) + ":"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "caption" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.cards.LevelCard.levels_system." +
                                          _vm.levelsSystem.current.toLowerCase() +
                                          ".name"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          ),
                          _vm.levelsSystem.next
                            ? _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "primary_font--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.cards.LevelCard.next"
                                            ) + ":"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.cards.LevelCard.levels_system." +
                                              _vm.levelsSystem.next.toLowerCase() +
                                              ".name"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { cols: "12", xs: "12", sm: "6" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pb-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-1", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "primary_font--text font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.cards.LevelCard.level_update_header"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "span",
                                { staticClass: "primary_font--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.cards.LevelCard.status"
                                        ) + ":"
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              !_vm.customer.level_reset_pending
                                ? _c(
                                    "span",
                                    { staticClass: "caption success--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.cards.LevelCard.status_updated"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "caption error--text" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.cards.LevelCard.status_pending"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c("v-skeleton-loader", { attrs: { type: "card" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }