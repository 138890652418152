var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    _vm._b({}, "v-list-item", _vm.computedListItemProps, false),
    [
      _c(
        "v-list-item-avatar",
        [
          _vm.benefit.icon_url
            ? _c("v-img", { attrs: { src: _vm.benefit.icon_url } })
            : _c("v-icon", [_vm._v("fas fa-user-check")])
        ],
        1
      ),
      _c(
        "v-list-item-content",
        [
          _c(
            "v-list-item-title",
            { staticClass: "mb-1 font-weight-bold text-left" },
            [_vm._v(" " + _vm._s(_vm.benefit.name) + " ")]
          ),
          _vm.completeDescription
            ? _c(
                "v-list-item-content",
                { staticClass: "text-left py-0 body-2 description" },
                [_vm._v(" " + _vm._s(_vm.benefit.description) + " ")]
              )
            : _c("v-list-item-subtitle", { staticClass: "text-left" }, [
                _vm._v(" " + _vm._s(_vm.benefit.description) + " ")
              ])
        ],
        1
      ),
      !_vm.readonly
        ? _c(
            "v-list-item-action",
            [
              _c(
                "GenericLoadingButton",
                {
                  attrs: {
                    function: _vm.deleteBenefit,
                    loading: _vm.isLoading,
                    icon: ""
                  },
                  on: {
                    "update:loading": function($event) {
                      _vm.isLoading = $event
                    },
                    executed: _vm.onExecuted
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "", color: "red" } }, [
                    _vm._v("fas fa-trash")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }