var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "ml-3 ml-md-3 pl-5 pl-md-12 section-title" }, [
        _vm._v(_vm._s(_vm.$t("views.customers.Details.title")))
      ]),
      _c("CustomerDetailsForm", {
        ref: "form",
        staticClass: "mb-4 mx-3",
        attrs: { id: _vm.id, document: _vm.document },
        model: {
          value: _vm.customer,
          callback: function($$v) {
            _vm.customer = $$v
          },
          expression: "customer"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }