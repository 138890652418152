<template>
    <v-btn
        v-bind="attrs"
        v-on="listeners"
        :loading="localLoading"
        @click.stop.prevent="executeFunction"
    >
        <slot></slot>
    </v-btn>
</template>
<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'GenericLoadingButton',
    inheritAttrs: false,
    props: {
        function: {
            required: true,
            type    : Function,
        },
    },
    data: vm => ({
        localLoading: false,
        hasError    : false,
    }),
    mixins: [ HasErrorHandlerMixin ],
    methods: {
        async executeFunction(v) {
            this.hasError     = false
            this.localLoading = true
            let res = await this.function()
                .catch(this.preErrorHandler)
            this.localLoading = false
            this.$emit('executed', res)
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
    watch: {
        localLoading(v) {
            this.$emit('update:loading', v)
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { click, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { loading, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>