<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-card class="my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0">
                    <v-card-title>
                        <div class="primary--text search-title">{{ $t('forms.CustomerDetailsForm.title') }}</div>
                    </v-card-title>

                    <v-card-text>
                        <div v-if="localDocumentLoading" class="d-flex mb-4 justify-center">
                            <v-progress-circular indeterminate/>
                        </div>
                        <SearchCustomerInput
                            v-else
                            ref="document_field"
                            fieldName="document"
                            :value.sync="value"
                            :document="localDocumentValue"
                            @changed-valididy="value => isCustomerValid = value"
                            @selected-customer="handleSelectedCustomer"
                        />

                        <p v-if="!value.document" class="mb-0" v-html="$t('forms.CustomerDetailsForm.no_document', { id })"></p>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <template v-if="isCustomerValid && selectedCustomer">
                <v-col cols="12" sm="12" md="4">
                    <CustomerCard :customer="selectedCustomer" class="my-0 mx-0 ml-md-8 py-0 px-8 limit-user-card"/>
                </v-col>

                <v-col cols="12" sm="12" md="8" :align-self="selectedCustomer ? null : 'center'">
                    <v-card class="my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0">
                        <v-card-text>
                            <span class="mt-1 primary--text section-title">{{ $t('forms.CustomerDetailsForm.subtitle') }}</span>

                            <!-- Botões de ação -->
                            <v-row class="mt-6">
                                <v-col cols="12" v-can:withdraw="'Voucher'" class="pt-0">
                                    <AccessCard :to="{ name: 'vouchers.withdraw', params: { id: selectedCustomer.id, document: selectedCustomer.document } }" :title="$t('forms.CustomerDetailsForm.withdraw_voucher')">
                                        <template #icon>
                                            <v-icon :size="25" color="primary">$cp_package</v-icon>
                                        </template>
                                    </AccessCard>
                                </v-col>

                                <v-col cols="12" xs="12" sm="12" md="6" v-can:create="'Generator'" class="pt-0 pr-md-1">
                                    <AccessCard :to="{ name: 'generate', params: { id: selectedCustomer.id, document: selectedCustomer.document } }" :title="$t('forms.CustomerDetailsForm.generate')">
                                        <template #icon>
                                            <v-icon :size="25" color="primary">$cp_list_check</v-icon>
                                        </template>
                                    </AccessCard>
                                </v-col>

                                <v-col cols="12" xs="12" sm="12" md="6" v-can:create="'Consumption'" class="pt-0 pl-md-1">
                                    <AccessCard :to="{ name: 'consumption', params: { id: selectedCustomer.id, document: selectedCustomer.document } }" :title="$t('forms.CustomerDetailsForm.consumption')">
                                        <template #icon>
                                            <v-icon :size="25" color="primary">$cp_database</v-icon>
                                        </template>
                                    </AccessCard>                               
                                </v-col>

                                <v-col cols="12" v-can:history="'Customer'" class="pt-0">
                                    <AccessCard :to="{ name: 'history', params: { id: selectedCustomer.id, document: selectedCustomer.document } }" :title="$t('forms.CustomerDetailsForm.history')">
                                        <template #icon>
                                            <v-icon :size="25" color="primary">$cp_invoice</v-icon>
                                        </template>
                                    </AccessCard>
                                </v-col>
                            </v-row>

                            <!-- Carregamento -->
                            <template v-if="localLoading">
                                <v-row>
                                    <v-col class="d-flex justify-center">
                                        <v-progress-circular class="mt-6" indeterminate/>
                                    </v-col>
                                </v-row>
                            </template>

                            <template v-else-if="consolidatedLevel">
                                <!-- Nível -->
                                <v-row>
                                    <v-col cols="12" class="mt-4">
                                        <span class="primary--text section-title">{{ $t('forms.CustomerDetailsForm.level') }}</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <LevelCard :customer="selectedCustomer" />
                                    </v-col>
                                </v-row>

                                <!-- Benefícios -->
                                <v-row>
                                    <v-col cols="12" class="mt-4">
                                        <span class="mt-5 primary--text section-title">{{ $t('forms.CustomerDetailsForm.benefits') }}</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-skeleton-loader v-if="!benefits.length && localLoading" type="list-item"/>
                                        <v-list v-if="benefits.length">
                                            <BenefitListItem v-for="(benefit, index) in benefits" :key="benefit.id" readonly :benefit="benefit" :index="index"/>
                                        </v-list>
                                        <div v-else-if="hasError">
                                            <v-alert type="info">{{ $t('forms.CustomerDetailsForm.error_benefits') }}</v-alert>
                                        </div>
                                        <div v-else>
                                            <v-alert type="info">{{ $t('forms.CustomerDetailsForm.no_benefits') }}</v-alert>
                                        </div>
                                    </v-col>
                                </v-row>

                            </template>

                            <!-- Opções avançadas -->
                            <v-row v-if="$can('dev')">
                                <v-col cols="12" class="mt-4">
                                    <span class="mt-5 primary--text section-title">{{ $t('forms.CustomerDetailsForm.advanced_settings.title') }}</span>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        :loading="devLoading"
                                        outlined color="primary"
                                        @click="forceLoginViaEmail"
                                        :disabled="selectedCustomer.hasLoginType($constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL'))"
                                    >{{ $t('forms.CustomerDetailsForm.advanced_settings.force_email_login_type') }}</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>

                            <template v-if="hasError">
                                <v-row>
                                    <v-col>
                                        <v-alert type="info">{{ $t('forms.CustomerDetailsForm.error_level') }}</v-alert>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-else>
                                <v-row>
                                    <v-col>
                                        <v-alert type="info">{{ $t('forms.CustomerDetailsForm.no_level') }}</v-alert>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
import BenefitListItem      from '@/shared/components/listItems/BenefitListItem'
import SearchCustomerInput  from '@/components/inputs/SearchCustomerInput'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import CustomerCard         from '@/shared/components/cards/CustomerCard.vue'
import AccessCard           from '@/components/cards/AccessCard'
import LevelCard            from '@/components/cards/LevelCard'
import BaseModel            from '@/models/BaseModel'
import FormMixin            from '@/mixins/FormMixin'
import Customer             from '@/models/Customer'
import Benefit              from '@/models/Benefit'

export default {
    name: 'GC_CommonForm',
    mixins: [ FormMixin(BaseModel, 'document_field'), HasErrorHandlerMixin ],
    components: {
        SearchCustomerInput,
        CustomerCard,
        LevelCard,
        BenefitListItem,
        AccessCard,
    },
    props: {
        id      : String,
        document: String,
    },
    data: vm => ({
        selectedCustomer    : null,
        localDocumentValue  : vm.document,
        consolidatedLevel   : null,
        benefits            : [],
        localLoading        : false,
        localDocumentLoading: false,
        hasError            : false,
        isCustomerValid     : false,
        devLoading          : false,
    }),
    async created() {
        if (this.id && !this.document)
            await this.fetchData()
    },
    mounted() {
        this.value.touch()
    },
    methods: {
        async fetchData() {
            this.localDocumentLoading = true

            let res = await (new Customer({ id: this.id })).get()
                .catch(this.preErrorHandler)

            if (!res)
                return

            let customer = new Customer(res.data)

            this.localDocumentValue   = customer.document
            await this.handleSelectedCustomer(customer)
            this.localDocumentLoading = false
            this.isCustomerValid      = true
        },
        presubmit() {
            if (!this.selectedCustomer)
                return

            this.localValue.customer_id = this.selectedCustomer.id

            return true
        },
        async handleSelectedCustomer(value) {
            this.selectedCustomer = value

            if (!value)
                return

            this.localLoading = true
            await this.fetchLevel()
            await this.fetchBenefits()
            this.localLoading = false

            if (value.id != this.id)
                this.$router.push({ name: 'customers.details', params: { id: value.id, document: value.document } })
        },
        async fetchLevel() {
            if (!this.selectedCustomer)
                return

            this.hasError = false
            this.selectedCustomer = await Customer.getById(this.selectedCustomer.id, { params: { update_level: 'TRUE' } })
                .catch(this.preErrorHandler)

            if (!this.selectedCustomer)
                return

            this.consolidatedLevel = this.$lodash.get(this.selectedCustomer, 'consolidated_level_data.level_data.level')
        },
        async fetchBenefits() {
            if (!this.selectedCustomer || !this.consolidatedLevel)
                return

            this.hasError = false
            let response = await Benefit.list(this.consolidatedLevel.id)
                .catch(this.preErrorHandler)

            if (!response)
                return

            this.benefits = response.data
        },
        preErrorHandler(e) {
            let status    = this.$lodash.get(e, 'response.status')
            this.hasError = true

            if (status == 404) {
                this.$router.push({ name: 'customers.find' })
            }

            this.errorHandler(e)
        },

        async forceLoginViaEmail() {
            this.devLoading = true

            this.selectedCustomer.login_type = this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')
            let res = await this.selectedCustomer.update()
                .catch(this.errorHandler)

            this.devLoading = false
            
            if (!res)
                return

            await new Promise((resolve) => {
                this.$bus.$emit('message', this.$t('messages.success'), 'success')
            })
        },
    },
 }
</script>

<style lang="scss" scoped>
.search-title {
    font-size: 16px;
    font-weight: bold;
}

.section-title {
    font-size: 16px !important;
    font-weight: bold !important;
}
</style>