var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-progress-linear", {
        attrs: {
          value: _vm.level.getProgress(_vm.accumulatedAmount),
          rounded: "",
          color: _vm.level.color,
          height: "20"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("small", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(Math.ceil(value)) + "%")
                ])
              ]
            }
          }
        ])
      }),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "pt-1" }, [
            _c("div", { staticClass: "float-left overline" }, [
              _vm._v(_vm._s(_vm.level.name))
            ]),
            _vm.nextLevel
              ? _c("div", { staticClass: "float-right overline" }, [
                  _vm._v(_vm._s(_vm.nextLevel.name))
                ])
              : _vm._e(),
            _c("div", { staticClass: "clear-fix" })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }