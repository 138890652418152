var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: "12", sm: "12", md: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0"
                },
                [
                  _c("v-card-title", [
                    _c("div", { staticClass: "primary--text search-title" }, [
                      _vm._v(_vm._s(_vm.$t("forms.CustomerDetailsForm.title")))
                    ])
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm.localDocumentLoading
                        ? _c(
                            "div",
                            { staticClass: "d-flex mb-4 justify-center" },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" }
                              })
                            ],
                            1
                          )
                        : _c("SearchCustomerInput", {
                            ref: "document_field",
                            attrs: {
                              fieldName: "document",
                              value: _vm.value,
                              document: _vm.localDocumentValue
                            },
                            on: {
                              "update:value": function($event) {
                                _vm.value = $event
                              },
                              "changed-valididy": function(value) {
                                return (_vm.isCustomerValid = value)
                              },
                              "selected-customer": _vm.handleSelectedCustomer
                            }
                          }),
                      !_vm.value.document
                        ? _c("p", {
                            staticClass: "mb-0",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t(
                                  "forms.CustomerDetailsForm.no_document",
                                  { id: _vm.id }
                                )
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isCustomerValid && _vm.selectedCustomer
            ? [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12", md: "4" } },
                  [
                    _c("CustomerCard", {
                      staticClass:
                        "my-0 mx-0 ml-md-8 py-0 px-8 limit-user-card",
                      attrs: { customer: _vm.selectedCustomer }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    attrs: {
                      cols: "12",
                      sm: "12",
                      md: "8",
                      "align-self": _vm.selectedCustomer ? null : "center"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass:
                          "my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0"
                      },
                      [
                        _c(
                          "v-card-text",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "mt-1 primary--text section-title"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("forms.CustomerDetailsForm.subtitle")
                                  )
                                )
                              ]
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mt-6" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "can",
                                        rawName: "v-can:withdraw",
                                        value: "Voucher",
                                        expression: "'Voucher'",
                                        arg: "withdraw"
                                      }
                                    ],
                                    staticClass: "pt-0",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c("AccessCard", {
                                      attrs: {
                                        to: {
                                          name: "vouchers.withdraw",
                                          params: {
                                            id: _vm.selectedCustomer.id,
                                            document:
                                              _vm.selectedCustomer.document
                                          }
                                        },
                                        title: _vm.$t(
                                          "forms.CustomerDetailsForm.withdraw_voucher"
                                        )
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: 25,
                                                      color: "primary"
                                                    }
                                                  },
                                                  [_vm._v("$cp_package")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        113742698
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "can",
                                        rawName: "v-can:create",
                                        value: "Generator",
                                        expression: "'Generator'",
                                        arg: "create"
                                      }
                                    ],
                                    staticClass: "pt-0 pr-md-1",
                                    attrs: {
                                      cols: "12",
                                      xs: "12",
                                      sm: "12",
                                      md: "6"
                                    }
                                  },
                                  [
                                    _c("AccessCard", {
                                      attrs: {
                                        to: {
                                          name: "generate",
                                          params: {
                                            id: _vm.selectedCustomer.id,
                                            document:
                                              _vm.selectedCustomer.document
                                          }
                                        },
                                        title: _vm.$t(
                                          "forms.CustomerDetailsForm.generate"
                                        )
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: 25,
                                                      color: "primary"
                                                    }
                                                  },
                                                  [_vm._v("$cp_list_check")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        1427803211
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "can",
                                        rawName: "v-can:create",
                                        value: "Consumption",
                                        expression: "'Consumption'",
                                        arg: "create"
                                      }
                                    ],
                                    staticClass: "pt-0 pl-md-1",
                                    attrs: {
                                      cols: "12",
                                      xs: "12",
                                      sm: "12",
                                      md: "6"
                                    }
                                  },
                                  [
                                    _c("AccessCard", {
                                      attrs: {
                                        to: {
                                          name: "consumption",
                                          params: {
                                            id: _vm.selectedCustomer.id,
                                            document:
                                              _vm.selectedCustomer.document
                                          }
                                        },
                                        title: _vm.$t(
                                          "forms.CustomerDetailsForm.consumption"
                                        )
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: 25,
                                                      color: "primary"
                                                    }
                                                  },
                                                  [_vm._v("$cp_database")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        1333574261
                                      )
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "can",
                                        rawName: "v-can:history",
                                        value: "Customer",
                                        expression: "'Customer'",
                                        arg: "history"
                                      }
                                    ],
                                    staticClass: "pt-0",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c("AccessCard", {
                                      attrs: {
                                        to: {
                                          name: "history",
                                          params: {
                                            id: _vm.selectedCustomer.id,
                                            document:
                                              _vm.selectedCustomer.document
                                          }
                                        },
                                        title: _vm.$t(
                                          "forms.CustomerDetailsForm.history"
                                        )
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "icon",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: 25,
                                                      color: "primary"
                                                    }
                                                  },
                                                  [_vm._v("$cp_invoice")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        false,
                                        287141473
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.localLoading
                              ? [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex justify-center"
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            staticClass: "mt-6",
                                            attrs: { indeterminate: "" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm.consolidatedLevel
                              ? [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-4",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "primary--text section-title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.CustomerDetailsForm.level"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("LevelCard", {
                                            attrs: {
                                              customer: _vm.selectedCustomer
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-4",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "mt-5 primary--text section-title"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.CustomerDetailsForm.benefits"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          !_vm.benefits.length &&
                                          _vm.localLoading
                                            ? _c("v-skeleton-loader", {
                                                attrs: { type: "list-item" }
                                              })
                                            : _vm._e(),
                                          _vm.benefits.length
                                            ? _c(
                                                "v-list",
                                                _vm._l(_vm.benefits, function(
                                                  benefit,
                                                  index
                                                ) {
                                                  return _c("BenefitListItem", {
                                                    key: benefit.id,
                                                    attrs: {
                                                      readonly: "",
                                                      benefit: benefit,
                                                      index: index
                                                    }
                                                  })
                                                }),
                                                1
                                              )
                                            : _vm.hasError
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-alert",
                                                    { attrs: { type: "info" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "forms.CustomerDetailsForm.error_benefits"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-alert",
                                                    { attrs: { type: "info" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "forms.CustomerDetailsForm.no_benefits"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            _vm.$can("dev")
                              ? _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mt-4",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mt-5 primary--text section-title"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "forms.CustomerDetailsForm.advanced_settings.title"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              loading: _vm.devLoading,
                                              outlined: "",
                                              color: "primary",
                                              disabled: _vm.selectedCustomer.hasLoginType(
                                                _vm.$constants.getConstant(
                                                  "CUSTOMER_LOGIN_TYPE_EMAIL"
                                                )
                                              )
                                            },
                                            on: {
                                              click: _vm.forceLoginViaEmail
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "forms.CustomerDetailsForm.advanced_settings.force_email_login_type"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [_c("v-divider")],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasError
                              ? [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-alert",
                                            { attrs: { type: "info" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.CustomerDetailsForm.error_level"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-alert",
                                            { attrs: { type: "info" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.CustomerDetailsForm.no_level"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }