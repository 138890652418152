<template>
    <!-- <v-list-item> -->
    <v-list-item v-bind="computedListItemProps">
        <v-list-item-avatar>
            <v-img v-if="benefit.icon_url" :src="benefit.icon_url"></v-img>
            <v-icon v-else>fas fa-user-check</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title class="mb-1 font-weight-bold text-left">
                {{ benefit.name }}
            </v-list-item-title>
            <v-list-item-content v-if="completeDescription" class="text-left py-0 body-2 description">
                {{ benefit.description }}
            </v-list-item-content>
            <v-list-item-subtitle v-else class="text-left">
                {{ benefit.description }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="!readonly">
            <GenericLoadingButton
                :function="deleteBenefit"
                :loading.sync="isLoading"
                icon @executed="onExecuted"
            >
                <v-icon small color="red">fas fa-trash</v-icon>
            </GenericLoadingButton>
        </v-list-item-action>
    </v-list-item>
</template>
<script>
import Benefit from '@/models/Benefit'
import GenericLoadingButton from '@/shared/components/buttons/GenericLoadingButton'

export default {
    name: 'SharedBenefitListItem',
    components: { GenericLoadingButton },
    data: vm => ({
        isLoading: false,
    }),
    props: {
        benefit: {
            type: Benefit,
            required: true,
        },
        index: {
            type: [Number, String],
            required: true,
        },
        completeDescription: {
            type: Boolean,
            default: false,
        },
        readonly: Boolean,
        deleteMessage: String,
    },
    methods: {
        async deleteBenefit() {
            if (this.readonly)
                return

            this.$emit('deleteStarted')
            return await this.benefit.delete()
        },
        onExecuted(res) {
            if (this.readonly)
                return
            
            if (this.deleteMessage)
                this.$bus.$emit('message', this.deleteMessage, 'success')
                
            this.$emit('refresh')
        },
    },
    computed: {
        computedListItemProps() {
            return this.readonly ? {} : {
                to: { name: 'levels.benefits.edit', params: { index: this.index, benefitId: this.benefit.id } },
                disabled: this.isLoading,
            }
        },
    },
}
</script>

<style>
.description {
    color: rgba(0, 0, 0, .6)
}
</style>