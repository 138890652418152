<template>
    <v-card v-if="consolidatedLevel && transitionLevel" outlined>
        <v-card-text>
            <!-- Informações do nível consolidado -->
            <v-row>
                <v-col cols="12" class="pb-0">
                    <span class="primary_font--text card-title">
                        {{ `${$t(`components.cards.LevelCard.levels_system.${levelsSystem.current.toLowerCase()}.consolidated_text`)}:` }}
                    </span>
                    <span class="ml-1 card-title-name" :style="consolidatedLevelNameStyle">
                        {{ consolidatedLevel.name }}
                    </span>
                </v-col>

                <v-col cols="12" class="pt-0">
                    <span>
                        {{ `${$t('components.cards.LevelCard.accumulated_amount')}:` }}
                    </span>
                    <span class="caption primary_font--text font-weight-bold">
                        {{ consolidatedAccumulatedAmount | dynamicPoint }}
                    </span>
                </v-col>
            </v-row>
            <LevelProgressBar
                :level="consolidatedLevel"
                :accumulatedAmount="consolidatedAccumulatedAmount"
            ></LevelProgressBar>

            <!-- Informações do nível de transição -->
            <div v-if="shouldShowTransitionLevelProgressBar">
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <span class="primary_font--text font-weight-bold card-title">
                            {{ `${$t('components.cards.LevelCard.levels_system.renovation.transition_text')}:` }}
                        </span>
                        <span class="ml-1 card-title-name" :style="transitionLevelNameStyle">
                            {{ transitionLevel.name }}
                        </span>
                    </v-col>

                    <v-col cols="12" class="pt-0">
                        <span>
                            {{ `${$t('components.cards.LevelCard.accumulated_amount')}:` }}
                        </span>
                        <span class="caption primary_font--text font-weight-bold">
                            {{ transitionAccumulatedAmount | dynamicPoint }}
                        </span>
                    </v-col>
                </v-row>
                <LevelProgressBar
                    :level="transitionLevel"
                    :accumulatedAmount="transitionAccumulatedAmount"
                ></LevelProgressBar>
            </div>

            <v-divider class="my-4"></v-divider>
            
            <v-row class="px-4">
                <!-- Status do sistema de níveis -->
                <v-col cols="12" xs="12" sm="6" class="pb-sm-0">
                    <v-row no-gutters class="pb-2">
                        <v-col cols="12" class="pb-1">
                            <span class="primary_font--text font-weight-bold">
                                {{ $t('components.cards.LevelCard.levels_system_header') }}
                            </span>
                        </v-col>

                        <v-col cols="12" class="py-0 ">
                            <span class="primary_font--text">
                                {{ `${$t('components.cards.LevelCard.status')}:` }}
                            </span>
                            <span v-if="!levelsSystem.next" class="caption success--text">
                                {{ $t('components.cards.LevelCard.status_updated') }}
                            </span>
                            <span v-else class="caption error--text">
                                {{ $t('components.cards.LevelCard.status_pending') }}
                            </span>
                        </v-col>

                        <v-col cols="12" class="py-0">
                            <span class="primary_font--text">
                                {{ `${$t('components.cards.LevelCard.current')}:` }}
                            </span>
                            <span class="caption">
                                {{ $t(`components.cards.LevelCard.levels_system.${levelsSystem.current.toLowerCase()}.name`) }}
                            </span>
                        </v-col>

                        <v-col v-if="levelsSystem.next" cols="12" class="py-0">
                            <span class="primary_font--text">
                                {{ `${$t('components.cards.LevelCard.next')}:` }}
                            </span>
                            <span class="caption">
                                {{ $t(`components.cards.LevelCard.levels_system.${levelsSystem.next.toLowerCase()}.name`) }}
                            </span>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Status da atualização de nível -->
                <v-col cols="12" xs="12" sm="6" class="pb-0">
                    <v-row no-gutters class="pb-2">
                        <v-col cols="12" class="pb-1">
                            <span class="primary_font--text font-weight-bold">
                                {{ $t('components.cards.LevelCard.level_update_header') }}
                            </span>
                        </v-col>

                        <v-col cols="12" class="py-0">
                            <span class="primary_font--text">
                                {{ `${$t('components.cards.LevelCard.status')}:` }}
                            </span>
                            <span v-if="!customer.level_reset_pending" class="caption success--text">
                                {{ $t('components.cards.LevelCard.status_updated') }}
                            </span>
                            <span v-else class="caption error--text">
                                {{ $t('components.cards.LevelCard.status_pending') }}
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <v-skeleton-loader v-else type="card"/>
</template>

<script>
import LevelProgressBar from '@/components/progressBars/LevelProgressBar'
import ConsolidatedLevelData from '@/models/ConsolidatedLevelData'
import TransitionLevelData from '@/models/TransitionLevelData'
import Customer from '@/models/Customer'

export default {
    name: 'LevelCard',
    props: {
        customer: {
            type: Customer,
            required: true,
        },
    },
    components: { LevelProgressBar },
    computed: {
        shouldShowTransitionLevelProgressBar() {
            // Somente deve exibir a barra de progresso do sistema do nível de transição caso o sistema de níveis seja RENOVATION
            return this.$lodash.get(this.customer, 'levels_system.current') == this.$constants.getConstant('RENOVATION_LEVELS_SYSTEM')
        },
        consolidatedLevelNameStyle() {
            return { color: this.consolidatedLevel.color }
        },
        transitionLevelNameStyle() {
            return { color: this.transitionLevel.color }
        },
        levelsSystem() {
            return this.customer.levels_system
        },
        consolidatedLevelData() {
            return this.customer.consolidated_level_data
        },
        transitionLevelData() {
            return this.customer.transition_level_data
        },
        consolidatedLevel() {
            return this.customer.consolidated_level
        },
        transitionLevel() {
            return this.customer.transition_level
        },
        consolidatedAccumulatedAmount() {
            return this.customer.consolidated_accumulated_amount
        },
        transitionAccumulatedAmount() {
            return this.customer.transition_accumulated_amount
        },
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.card-title {
    font-size: 16px !important;
    font-weight: bold !important;
}

.card-title-name {
    font-size: 18px !important;
    font-weight: bold !important;
}
</style>