<template>
    <div>
        <span class="ml-3 ml-md-3 pl-5 pl-md-12 section-title">{{ $t('views.customers.Details.title') }}</span>
        <CustomerDetailsForm
            ref="form"
            class="mb-4 mx-3"
            v-model="customer"
            :id="id"
            :document="document"
        ></CustomerDetailsForm>
    </div>
</template>

<script>
import CustomerDetailsForm from '@/components/forms/CustomerDetailsForm'
import Customer from '@/models/Customer'

export default {
    name: 'CustomersDetails',
    components: { CustomerDetailsForm },
    props: {
        id: String,
        document: String,
    },
    data: vm => ({
        customer: new Customer,
    }),
}
</script>