<template>
    <div>
        <v-progress-linear :value="level.getProgress(accumulatedAmount)" rounded  :color="level.color" height="20">
            <template v-slot="{ value }">
                <small class="font-weight-bold">{{ Math.ceil(value) }}%</small>
            </template>
        </v-progress-linear>
        <v-row>
            <v-col class="pt-1">
                <div class="float-left overline">{{ level.name }}</div>
                <div v-if="nextLevel" class="float-right overline">{{ nextLevel.name }}</div>
                <div class="clear-fix"></div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Level from '@/models/Level'
import { mapGetters } from 'vuex'

export default {
    name: 'LevelProgressBar',
    props: {
        level: {
            type    : Level,
            required: true,
        },
        accumulatedAmount: {
            type    : Number,
            required: true,
        },
    },
    data: () => ({
        nextLevel: undefined,
    }),
    created() {
        this.nextLevel = this.getNextLevel()
    },
    methods: {
        getNextLevel() {
            let nextLevelId = this.$lodash.get(this.level, 'next.id')
            if (!nextLevelId)
                return

            return this.$store.getters['level/getLevelById'](nextLevelId)
        }
    },
}
</script>